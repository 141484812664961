<template>
  <div>
    <v-container>
      <top-bar />
      <g-card :show-page-title="false" :elevation="0" class="mt-5"> </g-card>
    </v-container>
  </div>
</template>

<script>
import GCard from "@/components/GCard";
import TopBar from "@/components/menu/TopBar";
export default {
  name: "DashboardPage",
  metaInfo: {
    title: "Guest Post App",
    titleTemplate: "Home | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: {
    TopBar,
    GCard,
  },
};
</script>
