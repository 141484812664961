import SystemSettings from "@/services/SystemSettings";
export default {
  namespaced: true,
  state: {
    usdToBdt: 1,
  },
  mutations: {
    SET_USD_PRICE(state, payload) {
      state.usdToBdt = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: {
          data: { item },
        },
      } = await SystemSettings.all(payload);
      commit("SET_USD_PRICE", item.usd_to_bdt);
    },
  },
};
