import Api from "@/services/Api";
const all = (payload) => {
  const path = "v1/orders/all";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = (payload) => {
  const endpoint = `v1/order/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: "v1/order/store",
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: "v1/order/update",
    method: "PUT",
    data: payload,
  });
};

const updateStatus = (payload) => {
  return Api.postRequest({
    endpoint: "v1/order/update/status",
    method: "PUT",
    data: payload,
  });
};

export default {
  all,
  get,
  store,
  update,
  updateStatus,
};
