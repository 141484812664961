import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import site from "./site";
import order from "./order";
import setting from "./setting";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ["auth.authenticated", "auth.verified", "auth.user", "auth.token"],
});

export default new Vuex.Store({
  modules: {
    auth,
    site,
    order,
    setting,
  },
  plugins: [dataState],
  state: {
    snackBar: {},
    overlay: false,
    carts: [],
  },
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload;
    },
    SET_OVERLAY: function (state, payload) {
      state.overlay = payload;
    },
    SET_PRODUCT_CARTS: function (state, payload) {
      state.carts.push(payload);
    },
    REMOVE_CART_ITEM: function (state, index) {
      state.carts.splice(index, 1);
    },
    SET_EMPTY_CARTS: function (state) {
      state.carts = [];
    },
  },
  actions: {},
});
