import router from "@/router";
import auth from "@/services/Auth";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    verified: false,
    user: {},
    token: "",
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      const { name, email, currency } = state.user;
      return { name, email, currency };
    },
    currency(state) {
      const { currency } = state.user;
      return currency;
    },
    token(state) {
      return state.token;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_EMAIL_VERIFICATION(state, value) {
      state.verified = value;
    },
  },
  actions: {
    verified({ commit }) {
      return auth
        .verified()
        .then(
          ({
            data: {
              data: { verified },
            },
          }) => {
            commit("SET_EMAIL_VERIFICATION", verified);
          }
        )
        .catch(() => {
          commit("SET_EMAIL_VERIFICATION", false);
        });
    },
    login({ commit }) {
      return auth
        .auth()
        .then(
          ({
            data: {
              data: { item },
            },
          }) => {
            commit("SET_USER", item);
            commit("SET_AUTHENTICATED", true);
            router.push({ name: "dashboard" });
          }
        )
        .catch(() => {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          commit("SET_AUTHENTICATED", false);
        });
    },
    token({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
      router.push({ name: "login" });
    },
  },
};
