<template>
  <v-app>
    <left-sidebar />
    <v-main>
      <snackbar-component />
      <GOverlay />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
/*import HeaderComponent from "@/components/menu/Header";*/
import GOverlay from "@/components/GOverlay";
/*import VerificationComponent from "@/components/VerificationComponent";*/
import LeftSidebar from "@/components/menu/LeftSidebar";
export default {
  name: "App",
  metaInfo: {
    title: "Guest Post App",
    titleTemplate: "Home | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: {
    LeftSidebar,
    /* VerificationComponent,*/
    GOverlay,
    /*HeaderComponent,*/
    SnackbarComponent,
  },
  async mounted() {
    await this.$store.dispatch("setting/all");
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
  font-size: 18px !important;
}
/*For Input Field Right Align*/
.right-align-text input {
  text-align: right;
}
</style>
