import Order from "@/services/Order";
export default {
  namespaced: true,
  state: {
    orders: [],
  },
  getters: {
    orders(state) {
      return state.orders;
    },
  },
  mutations: {
    SET_ORDERS(state, payload) {
      state.orders = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await Order.all(payload);

      commit("SET_ORDERS", data);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Order.get(payload);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async updateStatus({}, payload) {
      return Order.updateStatus(payload);
    },
  },
};
