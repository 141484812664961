import Api from "@/services/Api";
const all = (payload) => {
  const path = "v1/setting";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

export default {
  all,
};
