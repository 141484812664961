import DashboardView from "@/views/Dashboard";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: { authOnly: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login"),
    meta: { guestOnly: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/auth/Signup"),
    meta: { guestOnly: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/auth/email/verify",
    name: "AuthEmailVerify",
    component: () => import("../views/auth/Verify"),
    meta: { guestOnly: true },
  },
  {
    path: "/auth/reset-password",
    name: "AuthResetPassword",
    component: () => import("../views/auth/ResetPassword"),
    meta: { guestOnly: true },
  },
  {
    path: "/sites",
    name: "sites",
    component: () => import("../views/Sites"),
    meta: { authOnly: true },
  },
  {
    path: "/create-order",
    name: "CreateOrder",
    component: () => import("../views/CreateOrder"),
    meta: { authOnly: true },
  },
  {
    path: "/order/checkout/:id",
    name: "OrderCheckout",
    component: () => import("../views/Checkout"),
    meta: { authOnly: true },
  },
  {
    path: "/my-order-list",
    name: "MyOrderList",
    component: () => import("../views/MyOrderList"),
    meta: { authOnly: true },
  },
  {
    path: "/upload/requirement/:id",
    name: "UploadRequirement",
    component: () => import("../views/RequirementPage"),
    meta: { authOnly: true },
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: () => import("../views/UserProfile"),
    meta: { authOnly: true },
  },
];

export default routes;
