<template>
  <v-navigation-drawer
    v-if="authenticated"
    :color="$vuetify.theme.themes.light.primary"
    dark
    app
    left
    fixed
    clipped
  >
    <v-card
      :light="!$vuetify.theme.dark"
      class="ma-2"
      color="#fafafa"
      elevation="0"
    >
      <v-card-text>
        <router-link to="/">
          <v-img :src="require('@/assets/logo.png')" max-height="50" />
        </router-link>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.action"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          block
          dark
          color="indigo darken-3"
          :loading="isLogoutLoading"
          @click="logoutHandler"
        >
          Logout
        </v-btn>
        <!--        <v-btn icon v-else @click="logoutHandler">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>-->
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Auth from "@/services/Auth";

export default {
  name: "LeftSidebar",
  data() {
    return {
      isLogoutLoading: false,
      mini: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", action: "/" },
        { title: "Sites", icon: "mdi-web", action: "/sites" },
        {
          title: "Create a New Order",
          icon: "mdi-reorder-horizontal",
          action: "/create-order",
        },
        { title: "My Order", icon: "mdi-account", action: "/my-order-list" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async logoutHandler() {
      this.isLogoutLoading = true;
      await Auth.logout()
        .then(({ data: { message } }) => {
          this.setSnackbar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackbar({ text: data.message, type: "error" });
        })
        .finally(() => {
          this.logout();
          this.isLogoutLoading = false;
        });
    },
    setSnackbar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", {
        type: type,
        text: text,
      });
    },
    userMenuHandler(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.sidebar {
  height: 100vh !important;
  top: 64px !important;
  transform: translateX(0%) !important;
  width: 220px !important;
  max-height: calc(100% - 64px) !important;
}
</style>
