import Site from "@/services/Site";
export default {
  namespaced: true,
  state: {
    sites: [],
  },
  getters: {
    sites(state) {
      return state.sites;
    },
  },
  mutations: {
    SET_SITES(state, payload) {
      state.sites = payload;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await Site.all(payload);

      commit("SET_SITES", data);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Site.get(payload);
    },
  },
};
