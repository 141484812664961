<template>
  <v-app-bar color="rgba(0,0,0,0)" flat>
    <v-text-field
      label="Search ..."
      class="pt-5"
      filled
      prepend-inner-icon="mdi-magnify"
      dense
      solo
      flat
      background-color="grey lighten-4"
      rounded
    ></v-text-field>
    <v-spacer></v-spacer>
    <!--    <v-badge color="error" content="6" bordered overlap class="mr-4">
      <v-icon>mdi-bell-outline</v-icon>
    </v-badge>-->
    <v-select
      dense
      :items="items"
      v-model="currentUser.currency"
      item-text="text"
      item-value="value"
      style="max-width: 100px"
      class="mr-5"
      @change="onchangeCurrencyHandler"
    />
    <router-link to="/create-order">
      <v-badge
        color="green"
        :content="totalCartItem"
        bordered
        overlap
        class="mr-5"
      >
        <v-icon>mdi-cart-outline</v-icon>
      </v-badge>
    </router-link>
    <v-menu
      v-if="authenticated"
      rounded="lg"
      offset-y
      open-on-hover
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <div v-bind="attrs" v-on="on">
          <span>{{ user.name }}</span>
          <v-avatar size="30" class="ml-2">
            <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
          </v-avatar>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="item in userMenu"
          :key="item.title"
          link
          @click="userMenuHandler(item.route)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-btn
            elevation="0"
            :loading="isLogoutLoading"
            @click="logoutHandler"
          >
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Logout from "@/mixins/Logout";
import Auth from "@/services/Auth";
export default {
  name: "TopBar",
  mixins: [Logout],
  data() {
    return {
      userMenu: [{ title: "Profile", route: "/user/profile" }],
      items: [
        { text: "USD", value: "USD" },
        { text: "BDT", value: "BDT" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState({
      currentUser: (state) => state.auth.user,
      totalCartItem: (state) => state.carts.length,
    }),
  },
  methods: {
    userMenuHandler(path) {
      this.$router.push(path);
    },
    async onchangeCurrencyHandler() {
      await Auth.update({ ...this.currentUser });
      this.$store.commit("auth/SET_USER", { ...this.currentUser });
    },
  },
};
</script>

<style scoped></style>
